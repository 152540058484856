import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { EnvConfig } from '@env-config';
import { getEnvironment } from './getEnvironment';

const envConfig = new EnvConfig();
const reactPlugin = new ReactPlugin();
let appInsights: ApplicationInsights | undefined;
const connectionString = envConfig.get('APP_INSIGHTS_CONNECTION_STRING');
const isLocal = envConfig.get('DEV') === 'true';

if (!connectionString) {
  console.error('App Insights connection string is missing');
} else {
  const appInsights = new ApplicationInsights({
    config: {
      connectionString: connectionString,
      extensions: [reactPlugin],
      enableAutoRouteTracking: true,
      disableAjaxTracking: false,
      autoTrackPageVisitTime: true,
      enableCorsCorrelation: true,
      enableRequestHeaderTracking: true,
      enableResponseHeaderTracking: true,
      correlationHeaderExcludedDomains: [
        '*.blob.core.windows.net',
        'analysis.chooose.today',
        'googleapis.com',
        'location.chooose.today',
        'tags.chooose.today',
        'telemetry.chooose.today',
        'vimeo.com',
        'ba-stg.identity.stg.iagl.digital'
      ],
    },
  });

  const partnerSlug = envConfig.get('CHOOOSE_PAGE_SLUG');
  /**
   * Filter exceptions that errorMessagesToIgnore[] includes to reduce garbage data passing to app insights
   */
  const errorMessagesToIgnore: string[] = ['Minified React error'];

  const shouldIgnoreError = (message: string): boolean => {
    return errorMessagesToIgnore.some(errorMessage => typeof errorMessage === 'string' && message.includes(errorMessage));
  };

  appInsights.addTelemetryInitializer(item => {
    if (!item) return;

    item.tags = item.tags || [];
    item.tags.push({ 'ai.cloud.role': 'lpg' });
    item.tags.push({ 'ai.cloud.roleInstance': `lpg-${partnerSlug}-${getEnvironment(isLocal, window.location.hostname)}` });

    if (item.baseType === 'ExceptionData') {
      for (const exception of item.baseData?.exceptions) {
        if (exception.message && shouldIgnoreError(exception.message)) {
          return false;
        }
      }
    }
  });

  appInsights.loadAppInsights();
  appInsights.trackPageView();
}
export { reactPlugin, appInsights };
