import { useEffect } from 'react';
import { isEmpty } from 'lodash';
import { useGlobalContext } from 'contexts/globalContext';
import { track, setEnvironment } from '../../utils/analytics/tagger';

const DataLayer = () => {
  const { partnerName, partnershipId, offsetAppType, offsetAppSlug } = useGlobalContext();

  useEffect(() => {
    const currentGTMGlobalData = JSON.parse(sessionStorage.getItem('gtmGlobalData') ?? 'null') ?? {};
    const shouldUpdateGlobalData =
      (!!offsetAppType && currentGTMGlobalData?.fields_to_set?.type_of_app !== offsetAppType) ||
      (!!offsetAppSlug && currentGTMGlobalData?.fields_to_set?.carbon_app_slug !== offsetAppSlug) ||
      isEmpty(currentGTMGlobalData);
    if ((!partnerName && !partnershipId) || !shouldUpdateGlobalData) return;
    const gmtGlobalData = {
      fields_to_set: {
        partnership_id: partnershipId ?? 'no_partnership_id',
        partnership_name: partnerName,
        environment: setEnvironment(),
        type_of_app: offsetAppType ?? '',
        carbon_app_slug: offsetAppSlug ?? '',
      },
    };

    try {
      sessionStorage.setItem('gtmGlobalData', JSON.stringify(gmtGlobalData));
    } catch {
      // do nothing
    }
    track({
      event: 'global-data-loaded',
    });
  }, [partnerName, partnershipId, offsetAppType, offsetAppSlug]);

  return null;
};

export default DataLayer;
