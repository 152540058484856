/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-ts-comment */

import Cookies from 'js-cookie';
import { EnvConfig } from '@env-config';

export const tags = {
  offsetApp: {
    calculate: {
      event: 'TrackingEvent',
      eventCategory: 'OffsetApp',
      eventAction: 'Calculate',
    },
    purchase: {
      name: 'purchase',
      affiliation: 'Offset app',
    },
  },
  blendedOffsetApp: {
    calculate: {
      event: 'TrackingEvent',
      eventCategory: 'BlendedOffsetApp',
      eventAction: 'Calculate',
    },
    continue: {
      event: 'TrackingEvent',
      eventCategory: 'BlendedOffsetApp',
      eventAction: 'Continue',
    },
    purchase: {
      name: 'purchase',
      affiliation: 'Blended offset app',
    },
  },
  formSubmit: {
    event: 'TrackingEvent',
    eventCategory: 'FormSubmit',
    eventAction: 'GetInTouch',
  },
};

type Environment = 'dev' | 'test' | 'production';

interface Product {
  currency?: string;
  item_category: string;
  item_category2: string | null;
  item_id: string;
  item_name: string;
  price: number;
  quantity: number;
}

interface Metadata {
  event: string;
  env: Environment;
  domain: string;
  categories: string[];
  transactionId: string;
  transactionAffiliation: string;
  transactionTotal: number;
  currencyCode: string;
  eventCategory: string;
  eventAction: string;
  eventLabel: string;
  eventValue?: Blended[];
  trackingEvent_generate_lead: {
    event_name: string;
    lead_type: string;
    partnership_id: string;
    environment: Environment;
  };
  fields_to_set: {
    partnership_id: string;
    partnership_name: string;
    environment: Environment;
    type_of_app: string;
    carbon_app_slug: string;
  };
  ecommerce: {
    value?: number;
    tax?: number;
    affiliation: string;
    transaction_id?: string;
    payment_type?: PaymentType;
    items: Product[];
  };
  trackingEvent_view_popup: {
    event_name: string;
    popup_type: string;
  };
}

interface Blended {
  portfolioName: string;
  portfolioId: string;
  percentage: number;
}

type PaymentType = 'Google pay' | 'Apple pay' | 'Card';

const setDomain = () => window.location.hostname.split('.')?.[0];

const setEnvironment = (): Environment => {
  const domain = setDomain();
  return domain.includes('dev') ? 'dev' : domain.includes('test') ? 'test' : 'production';
};

const setItemCategory = (totalKilosCo2: number): string => `${totalKilosCo2} kg CO2e`;

const setPaymentType = (type: string): PaymentType => {
  if (type === 'googlePay') {
    return 'Google pay';
  } else if (type === 'applePay') {
    return 'Apple pay';
  } else {
    return 'Card';
  }
};

const setOAAffiliation = (type: string | undefined): string => (type === 'blended' ? 'Blended offset app' : 'Offset app');

const setBlendedPercentage = (cartItem: PriceQuota): string | null => {
  const blendedPercentage = cartItem?.items[0]?.portfolioWeight ?? cartItem?.items[0]?.portfolio?.childPortfolios[0].weight ?? null;
  return blendedPercentage ? `${blendedPercentage}%` : null;
};

const baseTrack = (metadata: Partial<Metadata> = {}): void => {
  const consentCookie = Cookies?.get('chooose-cookies-consent-state');
  const trackingConsent = consentCookie ? JSON.parse(consentCookie).tracking : true;
  if (typeof window === 'undefined' || !trackingConsent) {
    return;
  }
  // @ts-ignore
  window.dataLayer = window.dataLayer || [];
  const envConfig = new EnvConfig();
  const partnershipId = envConfig.get('CHOOOSE_PARTNERSHIP_ID');
  const domain = setDomain();
  const env = setEnvironment();

  if (!domain) {
    return;
  }

  window.dataLayer.push(function () {
    // @ts-ignore
    this.reset();
  });
  let gtmGlobalData = {};
  try {
    gtmGlobalData = JSON.parse(sessionStorage.getItem('gtmGlobalData') ?? 'null') ?? {};
  } catch {
    // do nothing
  }
  // @ts-ignore
  window.dataLayer?.push({
    ...metadata,
    ...gtmGlobalData,
    ...(metadata.eventAction === 'ConfirmPayment' && {
      env,
      domain: domain.replace('-test', ''),
      chooosePartnershipId: partnershipId,
      transactionAffiliation: domain.replace('-test', ''),
    }),
  });
};

const trackWithLogger = new Proxy(baseTrack, {
  apply: (target, thisArg, argumentsList) => {
    console.log('Tracking event: \n', argumentsList[0]);
    return Reflect.apply(target, thisArg, argumentsList);
  },
});

const createEventItem = (priceQuota: PriceQuota) => {
  return {
    item_id: priceQuota.id,
    item_name: priceQuota.calculatorType ?? '',
    item_category: setItemCategory(priceQuota.totalKilosCo2),
    item_category2: setBlendedPercentage(priceQuota),
    price: priceQuota.totalPrice,
    currency: priceQuota.currency,
    quantity: priceQuota.items.length,
  };
};

const isDevEnvironment = () => {
  if (typeof window === 'undefined') {
    return false;
  }
  return Boolean(window.location.hostname.includes('localhost')) || Boolean(window.location.hostname.includes('dev.'));
};

const track = isDevEnvironment() ? trackWithLogger : baseTrack;

export { track, setEnvironment, setItemCategory, setPaymentType, setOAAffiliation, setBlendedPercentage, createEventItem };
