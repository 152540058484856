module.exports = [
  'en',
  'en-US',
  'de',
  'it',
  'es',
  'nl',
  'pl',
  'at',
  'is',
  'ar',
  'pt',
  'ja',
  'ru',
  'ko',
  'pt-BR',
  'es-MX',
  'zh',
  'zh-CN',
  'zh-TW',
  'zh-Hans',
  'zh-Hant',
  'cs',
  'da',
  'el',
  'fi',
  'fr',
  'hu',
  'nb',
  'no',
  'nb-NO',
  'ro',
  'sv',
  'tr',
  'uk',
  'id',
  'vi',
  'th',
  'tl',
  'ms',
  'ca',
];
