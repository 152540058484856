import bps from './mediaBreakpoints';

const breakpoints = {
  sm: `${bps.mediaBreakpoints[0]}px`,
  md: `${bps.mediaBreakpoints[1]}px`,
  lg: `${bps.mediaBreakpoints[2]}px`,
  xl: `${bps.mediaBreakpoints[3]}px`,
  '2xl': `${bps.mediaBreakpoints[4]}px`,
  '3xl': `${bps.mediaBreakpoints[5]}px`,
  fullHD: `${bps.mediaBreakpoints[6]}px`,
};

const mq = {
  sm: `@media (min-width: ${breakpoints.sm})`,
  md: `@media (min-width: ${breakpoints.md})`,
  mdMax: `@media (max-width: ${parseInt(breakpoints.md) - 1}px)`,
  lg: `@media (min-width: ${breakpoints.lg})`,
  lgMax: `@media (max-width: ${parseInt(breakpoints.lg) - 1}px)`,
  lgMinMax: `@media (min-width: ${breakpoints.lg}) and (max-width: ${parseInt(breakpoints.xl) - 1}px)`,
  xl: `@media (min-width: ${breakpoints.xl})`,
  xlMinMax: `@media (min-width: ${breakpoints.xl}) and (max-width: ${parseInt(breakpoints['2xl']) - 1}px)`,
  xxl: `@media (min-width: ${breakpoints['2xl']})`,
  xxxl: `@media (min-width: ${breakpoints['3xl']})`,
  fullHD: `@media (min-width: ${breakpoints.fullHD})`,
};

const appMaxWidth = 1530;
const appDesktopPadding = 35;
const appMobilePadding = 20;

export { mq, appMaxWidth, appDesktopPadding, appMobilePadding };

export default breakpoints;
