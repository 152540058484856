import { css, Global } from '@emotion/react';
import React, { FC } from 'react';

const GlobalStyles: FC = () => (
  <Global
    styles={css`
      html,
      body {
        margin: 0;
        padding: 0;
      }
      body {
        font-size: inherit;
      }
      .calculator-header {
        align-items: center !important;
        border-bottom: none !important;
        padding-bottom: 30px;
      }
      .calculator-tabs {
        flex-direction: column !important;
        padding: 0 !important;

        > div {
          width: 100%;
        }
      }
      .calculator-tabs-wrapper {
        padding: 0 !important;
        margin-top: 0 !important;
      }
      @media screen and (min-width: 992px) {
        html {
          overflow-y: scroll;
        }
      }
    `}
  />
);

export default GlobalStyles;
