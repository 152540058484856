import React, { ReactElement, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import qs from 'qs';
import { usePostHog } from 'posthog-js/react';
import { AppInsightsContext, AppInsightsErrorBoundary } from '@microsoft/applicationinsights-react-js';
import { reactPlugin } from 'utils/appInsights';
import { EnvConfig } from '@env-config';

type ImageAsset = {
  asset?: {
    url: string;
  };
};

export type PageContext = {
  id: string;
  lang?: string;
  mainLanguage?: string;
  currentLanguage?: string;
  additionalLanguages?: string[];
  originalPath?: string;
  indexSlug?: string;
  pageTitle?: string;
  socialMedia?: (string | null)[] | null;
  componentObject?: {
    twitterImage: ImageAsset;
    ogImage: ImageAsset;
  };
};

const PageContext = React.createContext<PageContext>({ id: '', additionalLanguages: [] });

const parseSpecificLanguage = (lng: string): string => {
  let newLang = lng;
  switch (lng) {
    case 'zh':
    case 'zh-CN':
      newLang = 'zh-Hans';
      break;
    case 'zh-TW':
      newLang = 'zh-Hant';
      break;
    case 'no':
    case 'nb':
      newLang = 'nb-NO';
      break;
  }

  return newLang;
};

export const PageContextProvider = ({
  pageContext = {} as PageContext,
  children,
}: {
  pageContext: PageContext;
  children: React.ReactNode;
}): ReactElement => {
  const envConfig = new EnvConfig();
  const { i18n } = useTranslation();
  const query = typeof window !== 'undefined' ? window.location.href.split('?')[1] ?? '' : '';
  const queryLang = ((qs.parse(query, { ignoreQueryPrefix: true })['lng'] as string) ?? '').split('#')[0] ?? '';
  let queryLangParsed = queryLang;
  const allSupportedLangs = [...(pageContext.additionalLanguages || []), pageContext.mainLanguage];
  const posthog = usePostHog();

  if (!allSupportedLangs.includes(queryLang)) {
    const parsedLng = parseSpecificLanguage(queryLang);
    queryLangParsed = !allSupportedLangs.includes(parsedLng) ? pageContext.mainLanguage || 'en' : parsedLng;
  }

  const isDevelopment = envConfig.get('DEV') === 'true';
  const shouldUseAppInsights = envConfig.get('DISABLE_APP_INSIGHTS') !== 'true' || !isDevelopment || !envConfig.get('APP_INSIGHTS_CONNECTION_STRING');

  const onLanguageChanged = (lng: string) => {
    const newLng = i18n.languages[0] || lng;

    const allSupportedLangs = [...(pageContext.additionalLanguages || []), pageContext.mainLanguage];

    if (!pageContext.originalPath) {
      return;
    }

    if (!allSupportedLangs.includes(newLng)) {
      const parsedLng = parseSpecificLanguage(newLng);
      i18n.changeLanguage(!allSupportedLangs.includes(parsedLng) ? pageContext.mainLanguage || 'en' : parsedLng);
      return;
    }
  };

  useEffect(() => {
    i18n.on('languageChanged', onLanguageChanged);
    return () => {
      i18n.off('languageChanged');
    };
  }, [i18n, pageContext]);

  const lang = queryLang ? queryLangParsed : pageContext.lang;

  useEffect(() => {
    lang && onLanguageChanged(lang);
  }, []);

  useEffect(() => {
    posthog.capture('Custom pageview', { title: pageContext.pageTitle });
  }, [pageContext.pageTitle]);

  return shouldUseAppInsights ? (
    <AppInsightsContext.Provider value={reactPlugin}>
      <AppInsightsErrorBoundary onError={() => <></>} appInsights={reactPlugin}>
        <PageContext.Provider value={{ ...pageContext, lang }}>{children}</PageContext.Provider>
      </AppInsightsErrorBoundary>
    </AppInsightsContext.Provider>
  ) : (
    <PageContext.Provider value={{ ...pageContext, lang }}>{children}</PageContext.Provider>
  );
};

export const usePageContext = () => React.useContext(PageContext);
