const isEnvironment = (host: string, envPattern: RegExp): boolean => {
  const chunks = host.split('.');
  return chunks.length > 0 && envPattern.test(chunks[0]);
};

export const getEnvironment = (isLocal: boolean, host: string): 'local' | 'dev' | 'test' | 'prod' => {
  if (isLocal || host === 'localhost') {
    return 'local';
  }
  if (isEnvironment(host, /\b\w+(?:-)?dev\b/)) {
    return 'dev';
  }
  if (isEnvironment(host, /\b\w+(?:-)?test\b/)) {
    return 'test';
  }
  return 'prod';
};
