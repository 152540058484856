import instance from './instance';

interface PartnershipResponse {
  name: string;
  id: string;
  multiplier?: {
    value: number;
  };
  allowedAirlineCodes?: string[];
  airportDataSet?: string;
  activeBonusRule?: PartnershipBonusRule;
  programmeName?: string | null;
  portalUrl?: string | null;
}

const getPartnership = async (): Promise<PartnershipResponse> => {
  const response = await instance.get<PartnershipResponse>(`/partnership`);
  return response.data;
};

const getPartnershipPortfolios = async (partnershipID: string): Promise<any> => {
  if (!partnershipID) return;
  const response = await instance.get<Portfolio>(`/partnerships/${partnershipID}/portfolios`);
  return response.data;
};

export { getPartnership, getPartnershipPortfolios };
