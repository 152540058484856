import axios, { AxiosError, InternalAxiosRequestConfig } from 'axios';
import { EnvConfig } from '@env-config';

function onRequest(config: InternalAxiosRequestConfig<unknown>) {
  return config;
}

function onResponseError(error: AxiosError): Promise<never> {
  const { config, response } = error;

  // NOTE(@pkgacek): This catches an `Quote not found` error and retries the API call
  // @see: https://dev.azure.com/CHOOOSE/CHOOOSE%20Platform/_workitems/edit/14779
  if (config && response?.data === 'Quote not found') {
    const delayRetryRequest = new Promise<void>(resolve => {
      setTimeout(() => {
        resolve();
      }, 1000);
    });
    return delayRetryRequest.then(() => instance(config));
  } else {
    return Promise.reject(response);
  }
}

const envConfig = new EnvConfig();
const choooseApiUrl = envConfig.get('CHOOOSE_API_URL');
const choooseApiFunctionKey = envConfig.get('CHOOOSE_API_FUNCTION_KEY');
const sanityProjectId = envConfig.get('SANITY_PROJECT_ID');
const sanityDataSet = envConfig.get('SANITY_DATASET');
const sanityApiUrl = `https://${sanityProjectId}.api.sanity.io/v1/data/query/${sanityDataSet}`;
const sanityToken = envConfig.get('SANITY_TOKEN');

const instance = axios.create({
  baseURL: choooseApiUrl,
  headers: { 'x-functions-key': choooseApiFunctionKey },
});
instance.interceptors.request.use(onRequest, undefined);
instance.interceptors.response.use(undefined, onResponseError);

// Sanity service
const sanityInstance = axios.create({
  baseURL: sanityApiUrl,
  headers: {
    Authorization: `Bearer ${sanityToken}`,
  },
});
sanityInstance.interceptors.request.use(onRequest, undefined);
sanityInstance.interceptors.response.use(undefined, onResponseError);

export default instance;
export { instance, sanityInstance };
