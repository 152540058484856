import React from 'react';
import { I18nextProvider } from 'react-i18next';
import 'tippy.js/animations/scale.css';
import i18n from './i18n';
import { Analytics } from './src/components/Analytics/Analytics';
import GlobalStyles from './src/styles/global';
import { PageContextProvider } from './src/contexts/pageContext';
import { GlobalContextProvider } from './src/contexts/globalContext';
import DataLayer from './src/components/DataLayer/DataLayer';
import { Auth0ProviderWithNavigate } from './src/sanityComponents/OffsetApp/modules/iaglAuth/OAAuth0Provider';

const auth0Domain = process.env.GATSBY_AUTH0_DOMAIN || '';
const auth0ClientId = process.env.GATSBY_AUTH0_CLIENT_ID || '';
const auth0RedirectUri = process.env.GATSBY_AUTH0_CALLBACK_URL || '';
const enableAuth0 = auth0Domain && auth0ClientId && auth0RedirectUri;

export const wrapRootElement = ({ element }) => {
  const content = (
    <>
      <Analytics />
      {element}
    </>
  );

  return (
    <GlobalContextProvider>
      <GlobalStyles />
      <DataLayer />
      <I18nextProvider i18n={i18n}>
        {enableAuth0 ? (
          <Auth0ProviderWithNavigate>{content}</Auth0ProviderWithNavigate>
        ) : (
          content
        )}
      </I18nextProvider>
    </GlobalContextProvider>
  );
};

export const wrapPageElement = ({ element, props }) => {
  return <PageContextProvider pageContext={props.pageContext}>{element}</PageContextProvider>;
};

const HeadComponents = [
  <script
    key="plasmic-hmr"
    type="text/javascript"
    dangerouslySetInnerHTML={{
      __html: `
        if (typeof window !== "undefined" && /\\/plasmic-host\\/?$/.test(window.location.pathname)) {
          const RealEventSource = window.EventSource;
          window.EventSource = function(url, config) {
            if (/[^a-zA-Z]hmr($|[^a-zA-Z])/.test(url)) {
              console.warn("Plasmic: disabled EventSource request for", url);
              return {
                onerror() {}, onmessage() {}, onopen() {}, close() {}
              };
            } else {
              return new RealEventSource(url, config);
            }
          }
        }
      `,
    }}
  />,
];

const isProduction = process.env.NODE_ENV === 'production';
const isPlasmic = process.env?.IS_PLASMIC === 'true';

export const onRenderBody = ({ pathname, setHeadComponents }) => {
  if (!isPlasmic) {
    // relevant only for Plasmic projects
    return;
  }

  /**
   * We add the preamble tag script to all pages during development mode
   * because during development all pages are dynamically rendered based
   * on `/` route, during production we add it only in `/plasmic-host/`
   */
  if (!isProduction || pathname === '/plasmic-host/') {
    setHeadComponents(HeadComponents);
  }
};
