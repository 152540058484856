import { theme as defaultTheme, extendTheme } from '@chakra-ui/react';
import bp from 'styles/breakpoints';
import colors from 'styles/colors';

const theme = {
  ...defaultTheme,
  breakpoints: { sm: bp.sm, md: bp.md, lg: bp.lg, xl: bp.xl, '2xl': bp['2xl'], '3xl': bp['3xl'], fullHD: bp.fullHD },
  colors: {
    ...defaultTheme.colors,
    pink: {
      ...defaultTheme.colors.pink,
      ...colors.pink,
    },
    blue: {
      ...defaultTheme.colors.blue,
      ...colors.blue,
    },
    green: {
      ...defaultTheme.colors.green,
      ...colors.green,
    },
    gray: {
      ...defaultTheme.colors.gray,
      ...colors.gray,
    },
    red: {
      ...defaultTheme.colors.red,
      ...colors.red,
    },
    accent: {
      ...colors.accent,
    },
    background: {
      ...colors.background,
    },
    border: {
      ...colors.border,
    },
  },
};

export default extendTheme(theme);
