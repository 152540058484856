/* eslint-disable @typescript-eslint/ban-ts-comment */
import { FC, useLayoutEffect } from 'react';
import { EnvConfig } from '@env-config';
const envConfig = new EnvConfig();

const Analytics: FC = () => {
  useLayoutEffect(() => {
    if (typeof window === 'undefined') {
      return;
    }

    const partnershipId = envConfig.get('CHOOOSE_PARTNERSHIP_ID');
    //Adding these variables to window will allow to consume them in Google Tag Manager
    //@ts-ignore
    window.chooosePartnershipId = partnershipId;
  }, []);

  return null;
};

export { Analytics };
